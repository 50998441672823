import parse from 'remark-parse';
import toc from 'remark-extract-toc';
import { unified } from 'unified';
import { DOCS_PATH } from '../constants';
import { getRoutes } from './treeData';

function generateMarkdownData(items) {
    items.forEach(i => {
        if (i.markdownPath) {
            i.usesMarkdown = true;
            const filePath = `${process.env.PUBLIC_URL}/${DOCS_PATH}/${i.markdownPath}`;
            fetch(filePath)
                .then(response => response.text())
                .then(data => {
                    const processor = unified().use(parse).use(toc, { tight: true });
                    const ast = processor.parse(data);
                    const extractedTocItems = ast.children.filter(node => node.type === 'heading' && node.depth < 4);
                    i.tocItems = extractedTocItems;
                    i.markdownContent = data;
                })
                .catch(error => {
                    i.error = error;
                });
        } else {
            i.usesMarkdown = false;
        }
    });

    return items;
}

const markdownData = generateMarkdownData(getRoutes())

export function getMarkdownData() {
    return markdownData;
}
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { Modal, Form, Input, Button, Checkbox, Radio, message } from 'antd';
import { LikeOutlined, DislikeOutlined } from '@ant-design/icons';

function FeedbackForm() {
    const [form] = Form.useForm();

    const [feedbackFormVisible, setFeedbackFormVisible] = useState(false); // Feedback form not visible when starting
    const [thumbsValue, setThumbsValue] = useState(null);
    const [cookies, setCookie] = useCookies(["trackingNumber"]);

    // We call this function when the feedback button is pressed
    const sendFeedbackData = async (data) => {
        debugger;
        const trackingNumber = cookies.trackingNumber ?? `SN-${Math.random().toString().slice(2,11)}`;
        setCookie("trackingNumber", trackingNumber, { path: "/", sameSite: "Strict" });

        const userAgent = window.navigator.userAgent;
        const url = window.location.href;
        const helpful = true ? "Yes" : "No";
        const tellMore = data.tellMore;

        const values = {
            "entry.2020737435": trackingNumber,
            "entry.427762658": url,
            "entry.1217352437": userAgent,
            "entry.1947012428": helpful,
            "entry.293758385": tellMore || "",
        }

        const urlValues = Object.keys(values)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`)
            .join("&")

        const urlCheckboxes = (data.reason || [])
            .map(r => `entry.1959300090=${encodeURIComponent(r)}`)
            .join("&");

        const urlParams = !!urlCheckboxes
            ? `${urlValues}&${urlCheckboxes}`
            : urlValues;

        const requestUrl = `https://docs.google.com/forms/d/e/1FAIpQLSctpHNNBG5avNdo3bWs8GRI0CJfzDNooF1B1TnAZuK7mlTucg/formResponse?&submit=Submit&${urlParams}`;

        await fetch(requestUrl, {
            method: 'GET',
            mode: 'no-cors',
        });
    }

    const handleSubmit = async () => {
        const values = await form.validateFields();
        sendFeedbackData(values);
        form.resetFields();
        message.success('Thank you for your feedback!');
        setFeedbackFormVisible(false);
    };

    const thumbsUpCheckboxes = [
        "Easy to understand",
        "Accomplished task",
        "Easy to find",
        "Learned something new",
        "Other reason",
    ];

    const thumbsDownCheckboxes = [
        "Content hard to understand",
        "Procedure or code doesn't work",
        "Couldn't find what I need",
        "Out of date",
        "Other reason",
    ];

    const checkboxesToShow = thumbsValue === 'thumbs-up' ? thumbsUpCheckboxes : thumbsDownCheckboxes;

    useEffect(() => {
        form.setFieldsValue({
            thumbs: thumbsValue,
        });
    }, [thumbsValue, form]);

    return (
        <>
            <div className="feedback">Feedback</div>
            <span className='semi-bold'>Was this page helpful?</span>
            <Button
                className={`thumbs-button ${thumbsValue === 'thumbs-up' ? 'selected' : ''}`} type="link"
                onClick={() => {
                    setFeedbackFormVisible(true);
                    setThumbsValue('thumbs-up');
                    sendFeedbackData({ thumbs: 'thumbs-up' });
                }}>
                <LikeOutlined /> Yes
            </Button>
            <Button
                className={`thumbs-button ${thumbsValue === 'thumbs-down' ? 'selected' : ''}`} type="link"
                onClick={() => {
                    setFeedbackFormVisible(true);
                    setThumbsValue('thumbs-down');
                    sendFeedbackData({ thumbs: 'thumbs-down' });
                }}>
                <DislikeOutlined /> No
            </Button>
            <Modal
                className='feedback-form'
                open={feedbackFormVisible}
                title="Was this page helpful?"
                onCancel={() => setFeedbackFormVisible(false)}
                footer={[
                    <Button key="cancel" onClick={() => setFeedbackFormVisible(false)}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={() => handleSubmit()}>
                        Submit Feedback
                    </Button>,
                ]}>
                <Form form={form}>
                    <Form.Item name="thumbs" label="">
                        <Radio.Group value={thumbsValue}>
                            <Radio value="thumbs-up" onClick={() => setThumbsValue('thumbs-up')}><LikeOutlined /> Yes</Radio>
                            <Radio value="thumbs-down" onClick={() => setThumbsValue('thumbs-down')}><DislikeOutlined /> No</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="reason" label="What is the reason for your feedback?" labelCol={{ span: 24 }}>
                        <Checkbox.Group className='checkbox-group'>
                            <ul>
                                {checkboxesToShow.map((checkbox) => (
                                    <li key={checkbox}>
                                        <Checkbox value={checkbox}>
                                            {checkbox.replace(/-/g, ' ')}
                                        </Checkbox>
                                    </li>
                                ))}
                            </ul>
                        </Checkbox.Group>
                    </Form.Item>
                    <Form.Item name="tellMore" label="Tell us more." labelCol={{ span: 24 }}>
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
                <em>
                    By submitting this form you agree to send ScourNomad entered data along with
                    your user agent, current url, and a unique number used to track your feedbacks
                    on this site and to help us with debugging.
                </em>
                <em>
                    ScourNomad will not collect any other data and will not track you outside the
                    aforementioned activity or outside the website. ScourNomad will not sell or
                    give away intentionally any of your private data.
                </em>
            </Modal>
        </>);
}

export default FeedbackForm;

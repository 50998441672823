import React, { useState, useEffect } from 'react';
import { Layout, Row, Col, Affix } from 'antd';
import './App.css';
import AppHeader from './header.js';
import AppFooter from './footer.js';
import CustomTree from './Tree.js';
import AnchorPoints from './AnchorPoints';
import FeedbackForm from './components/FeedbackForm';
import Document from './document';
import { Route, Routes } from 'react-router-dom';
import { getTreeData } from './data/treeData.js';
import { getMarkdownData } from './data/markdownData.js';

const { Content } = Layout;

function App() {

    const [selectedDocument, setselectedDocument] = useState(null); // Documentation Tree Selection
    const [feedbackVisible, setFeedbackVisible] = useState(false); // Feedback form not visible when starting
    const [selectedFeedback, setSelectedFeedback] = useState(null);
    const [selectedButton, setSelectedButton] = useState(null); // Used for CSS of the button

    // Fliters
    const [filterInput, setFilterInput] = useState('');
    const [isFilterInputEmpty, setIsFilterInputEmpty] = useState(true);
    const [filteredTreeData, setFilteredTreeData] = useState(getTreeData());


    const [isDisplayed, setIsDisplayed] = useState(false);

    useEffect(() => {
        setInterval(() => {
            setIsDisplayed(true);
        }, 1000);
    }, []);

    // Documentation Tree Selection
    const onSelect = (selectedKeys, info) => {
        setselectedDocument(selectedKeys[0]);
    };

    return (
        <Layout className="layout">
            {/* App Header */}
            <AppHeader />
            {/* App Body */}
            <Content className="page-content">
                <Row gutter={24}>
                    <Col xs={0} sm={0} md={6} lg={4} xl={4}>
                        {/* App Tree */}
                        <Affix offsetTop={20}>
                            {/* <Input className="filter-input" placeholder="Filter By" prefix={<FilterOutlined />} /> */}
                            {/* <Input
                                    className="filter-input"
                                    placeholder="Filter By"
                                    prefix={<FilterOutlined />}
                                    value={filterInput}
                                    onChange={handleFilterInputChange} /> */}
                            <CustomTree onSelect={onSelect} treeData={filteredTreeData} />

                            {/* <CustomTree onSelect={onSelect} /> */}
                        </Affix>
                    </Col>
                    <Col xs={24} sm={24} md={14} lg={16} xl={16}>
                        {/* Markdown Files */}
                        <Layout className="main-layout" visible={isDisplayed}>
                            <Content className="main-content">
                                <Routes>
                                    {getMarkdownData().map(r => <Route path={r.href} element={<Document document={r} routes={getMarkdownData()} isDisplayed={isDisplayed} />} />)}
                                </Routes>
                            </Content>
                        </Layout>
                    </Col>
                    <Col xs={0} sm={0} md={4} lg={4} xl={4}>
                        {/* Anchor Points */}
                        <Affix offsetTop={20} className="right-side-anchor">
                            <Routes basename={process.env.PUBLIC_URL}>
                                {getMarkdownData().map(r => <Route path={r.href} element={<AnchorPoints leaf={r} />} />)}
                            </Routes>
                        </Affix>
                    </Col>
                </Row>
                {/* Feedback Form */}
                <Row gutter={24}>
                    <Col xs={0} sm={0} md={6} lg={4} xl={4}>
                    </Col>
                    <Col>
                        <FeedbackForm />
                    </Col>
                    <Col xs={0} sm={0} md={4} lg={4} xl={4}>

                    </Col>
                </Row>
            </Content>
            {/* Application Footer */}
            <AppFooter />
        </Layout>
    );
}

export default App;

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';
import Breadcrumbs from './Breadcrumbs';
import PreBlock from './components/PreBlock';
import LinkBlock from './components/LinkBlock';

const Document = (props) => {
    const [markdownContent, setMarkdownContent] = useState("");

    useEffect(() => {
        setMarkdownContent(props.document.markdownContent);
    }, [props.document.markdownContent]);

    return (
        <div>
            <Breadcrumbs document={props.document} routes={props.routes} />
            <div className='loading-text' style={props.isDisplayed ? { display: "none" } : { display: "block" }}>
                <h1>Loading...</h1>
            </div>
            <ReactMarkdown
                className="markdown-content"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeSlug, rehypeRaw]}
                components={{ pre: PreBlock, a: LinkBlock }}>
                {markdownContent}
            </ReactMarkdown>
        </div>
    );
};

export default Document;

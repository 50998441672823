import React from 'react';
import { Tree } from 'antd';
import { Link } from 'react-router-dom';

const CustomTree = ({ onSelect, treeData }) => {
    const generateTreeNodes = (nodes) => {
        return nodes.map((node) => {
            if (node.children) {
                return {
                    ...node,
                    title: (
                        node.href
                            ? <Link to={node.href}>{node.title}</Link>
                            : <>{node.title}</>
                    ),
                    children: generateTreeNodes(node.children),
                };
            }
            return {
                ...node,
                title: (
                    node.href
                        ? <Link to={node.href}>{node.title}</Link>
                        : <>{node.title}</>
                ),
            };
        });
    };

    const formattedTreeData = generateTreeNodes(treeData);

    return (
        <Tree
            defaultExpandAll // Expand the tree to reveal all nodes
            onSelect={onSelect}
            treeData={formattedTreeData}
        />
    );
};


export default CustomTree;

import React, { useState, useEffect } from 'react';
import { Anchor } from 'antd';

const { Link } = Anchor;

// Turn heading titles into IDs for href in <Anchor>
const slugify = text =>
    text.toLowerCase()
        .replace(/[^\w\s-]/g, '')
        .replace(/[\s_-]+/g, '-');

const AnchorPoints = (props) => {
    const [anchorPoints, setAnchorPoints] = useState([]);

    useEffect(() => {
        setAnchorPoints(props.leaf.tocItems);
    }, [props.leaf.tocItems]);

    return (
        <div>
            <Anchor>
                {
                    anchorPoints?.map(item => {
                        const headingText = item.children[0].value;
                        const headingId = slugify(headingText);
                        return (
                            <Link key={item.position.start.offset} href={`#${headingId}`} title={headingText} />
                        );
                    })
                }
            </Anchor>
        </div>
    );
};

export default AnchorPoints;

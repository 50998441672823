// Tree data:
// * title - How the item will be displayed on the left
// * href - The location (URL) that item will take us to like /workflows -> http://example.com/workflows
// * markdownPath - The file that will be loaded from the docs/ direcotry, currently
// * children - The node's subnodes
const treeData = [
    {
        title: 'Overview',
        href: '/',
        markdownPath: 'overview.md',
    },
    {
        title: 'Glossary',
        href: '/glossary',
        markdownPath: 'glossary.md',
    },
    {
        title: 'Getting started',
        href: '/get-started',
        markdownPath: 'get-started.md',
        children: [
            {
                title: 'Hit-the-ground-running: run a scan',
                href: '/hit-ground-running/hgr-run-scan',
                markdownPath: 'hit-ground-running/hgr-run-scan.md',
            },
            {
                title: 'Hit-the-ground-running: implement a programmable node',
                href: '/hit-ground-running/hgr-implement-node',
                markdownPath: 'hit-ground-running/hgr-implement-node.md',
            },
        ],
    },
    {
        title: 'General concepts',
        href: '/general-concepts',
        markdownPath: 'general-concepts.md',
        children: [
            {
                title: 'Markdown',
                href: '/general-concepts/markdown',
                markdownPath: 'general-concepts/markdown.md',
            },
            {
                title: 'Internals',
                href: '/general-concepts/internals',
                markdownPath: 'general-concepts/internals.md',
            },
            {
                title: 'Technical listings',
                href: '/general-concepts/technical-listings',
                markdownPath: 'general-concepts/technical-listings.md',
                children: [
                    {
                        title: 'Data Types',
                        href: '/general-concepts/technical-listings/data-types',
                        markdownPath: 'general-concepts/technical-listings/data-types.md',
                    },
                    {
                        title: 'Simple data objects',
                        href: '/general-concepts/technical-listings/simple-data-objects',
                        markdownPath: 'general-concepts/technical-listings/simple-data-objects.md',
                    },
                    {
                        title: 'Basic converters',
                        href: '/general-concepts/technical-listings/basic-converters',
                        markdownPath: 'general-concepts/technical-listings/basic-converters.md',
                    },
                    {
                        title: 'Setting keys',
                        href: '/general-concepts/technical-listings/setting-keys',
                        markdownPath: 'general-concepts/technical-listings/setting-keys.md',
                    },
                ]
            }
        ]
    },
    {
        title: 'User interface',
        href: '/user-interface',
        markdownPath: 'user-interface.md',
        children: [
            {
                title: 'Projects',
                href: '/user-interface/projects',
                markdownPath: 'user-interface/projects.md',
                children: [
                    {
                        title: 'Seed resource examples',
                        href: '/user-interface/projects/seed-resource-examples',
                        markdownPath: 'user-interface/seed-resource-examples.md',
                    },
                ]
            },
            {
                title: 'Workflows',
                href: '/user-interface/workflows',
                markdownPath: 'user-interface/workflows.md',
            },
            {
                title: 'Scans',
                href: '/user-interface/scans',
                markdownPath: 'user-interface/scans.md',
            },
            {
                title: 'Programmable nodes',
                href: '/user-interface/programmable-nodes',
                markdownPath: 'user-interface/programmable-nodes.md',
            },
            {
                title: 'Filesystem',
                href: '/user-interface/filesystem',
                markdownPath: 'user-interface/filesystem.md',
            },
            {
                title: 'Settings',
                href: '/user-interface/settings',
                markdownPath: 'user-interface/settings.md',
            },
        ]
    },
    {
        title: 'Programmable nodes reference',
        href: '/programmable-nodes-reference',
        markdownPath: 'programmable-nodes-reference.md',
        children: [
            {
                title: 'Reference',
                href: '/programmability/reference',
                markdownPath: '/programmability/reference.md',
                children: [
                    {
                        title: 'Overview',
                        href: '/programmability/reference/overview',
                        markdownPath: 'programmability/reference/overview.md',
                    },
                    {
                        title: 'Manifest',
                        href: '/programmability/reference/manifest',
                        markdownPath: 'programmability/reference/manifest.md',
                        children: [
                            {
                                title: 'Entries, Exists, and Properties',
                                href: '/programmability/reference/manifest/entries-exits-props',
                                markdownPath: 'programmability/reference/entries-exits-props.md',
                            },
                            {
                                title: 'Manifest samples',
                                href: '/programmability/reference/manifest/manifest-sample',
                                markdownPath: 'programmability/reference/manifest-sample.md',
                            },
                        ],
                    },
                    {
                        title: 'Getting input data',
                        href: '/programmability/reference/getting-input-data',
                        markdownPath: 'programmability/reference/getting-input-data.md',
                    },
                    {
                        title: 'Producing outputs',
                        href: '/programmability/reference/producing-outputs',
                        markdownPath: 'programmability/reference/producing-outputs.md',
                    },
                    {
                        title: 'Handling errors',
                        href: '/programmability/reference/handling-errors',
                        markdownPath: 'programmability/reference/handling-errors.md',
                    },
                ]
            },
            {
                title: 'Language library reference',
                href: '/programmability/libraries',
                markdownPath: 'programmability/libraries.md',
                children: [
                    {
                        title: 'Python 3',
                        href: '/programmability/libraries/python-3',
                        markdownPath: 'programmability/libraries/python-3.md',
                        children: [
                            {
                                title: 'Examples',
                                href: '/programmability/libraries/python-3/examples',
                                markdownPath: 'programmability/libraries/examples.md',
                                children: [
                                    {
                                        title: 'Entrypoint samples',
                                        href: '/programmability/libraries/python-3/entrypoint-samples',
                                        markdownPath: 'programmability/libraries/python-3/entrypoint-samples.md',
                                    },
                                    {
                                        title: 'Example 1: Using Scrapy library',
                                        href: '/programmability/libraries/python-3/example1',
                                        markdownPath: 'programmability/libraries/python-3/example1.md',
                                    },
                                ]
                            },
                        ]
                    },
                ]
            },
        ]
    },
    {
        title: 'Nodes',
        href: '/nodes',
        markdownPath: 'nodes.md',
        children: [
            {
                title: 'General reference',
                href: 'nodes/general-reference',
                markdownPath: 'nodes/general-reference.md',
            },
            {
                title: 'Enumeration',
                href: 'nodes/enum',
                markdownPath: 'nodes/enum.md',
                children: [
                    {
                        title: 'Amass v4',
                        href: '/nodes/enum/amass-v4',
                        markdownPath: 'nodes/enum/amass-v4.md',
                    },
                    {
                        title: 'Aquatone',
                        href: '/nodes/enum/aquatone',
                        markdownPath: 'nodes/enum/aquatone.md',
                    },
                    {
                        title: 'Arjun',
                        href: '/nodes/enum/arjun',
                        markdownPath: 'nodes/enum/arjun.md',
                    },
                    {
                        title: 'DnsX',
                        href: '/nodes/enum/dnsx',
                        markdownPath: 'nodes/enum/dnsx.md',
                    },
                    {
                        title: 'EyeWitness',
                        href: '/nodes/enum/eyewitness',
                        markdownPath: 'nodes/enum/eyewitness.md',
                    },
                    {
                        title: 'FFUF',
                        href: '/nodes/enum/ffuf',
                        markdownPath: 'nodes/enum/ffuf.md',
                    },
                    {
                        title: 'GAU',
                        href: '/nodes/enum/gau',
                        markdownPath: 'nodes/enum/gau.md',
                    },
                    {
                        title: 'Hakrawler',
                        href: '/nodes/enum/hakrawler',
                        markdownPath: 'nodes/enum/hakrawler.md',
                    },
                    {
                        title: 'Httprobe',
                        href: '/nodes/enum/httprobe',
                        markdownPath: 'nodes/enum/httprobe.md',
                    },
                    {
                        title: 'HttpX',
                        href: '/nodes/enum/httpx',
                        markdownPath: 'nodes/enum/httpx.md',
                    },
                    {
                        title: 'Kiterunner',
                        href: '/nodes/enum/kiterunner',
                        markdownPath: 'nodes/enum/kiterunner.md',
                    },
                    {
                        title: 'Masscan',
                        href: '/nodes/enum/masscan',
                        markdownPath: 'nodes/enum/masscan.md',
                    },
                    {
                        title: 'Naabu',
                        href: '/nodes/enum/naabu',
                        markdownPath: 'nodes/enum/naabu.md',
                    },
                    {
                        title: 'ParamSpider',
                        href: '/nodes/enum/paramspider',
                        markdownPath: 'nodes/enum/paramspider.md',
                    },
                    {
                        title: 'Sublist3r',
                        href: '/nodes/enum/sublist3r',
                        markdownPath: 'nodes/enum/sublist3r.md',
                    },
                ]
            },
            {
                title: 'Offense',
                href: 'nodes/offense',
                markdownPath: 'nodes/offense.md',
                children: [
                    {
                        title: 'Nuclei',
                        href: '/nodes/offense/nuclei',
                        markdownPath: 'nodes/offense/nuclei.md',
                    },
                ]
            },
            {
                title: 'Custom implemetations',
                href: 'nodes/custom-implementations',
                markdownPath: 'nodes/custom-implementations.md',
            },
            {
                title: 'Programmability',
                href: 'nodes/programmability',
                markdownPath: 'nodes/programmability.md',
                children: [
                    {
                        title: 'Seed Resources',
                        href: '/nodes/programmability/seed-resources',
                        markdownPath: 'nodes/programmability/seed-resources.md',
                    },
                    {
                        title: 'Converter',
                        href: '/nodes/programmability/converter',
                        markdownPath: 'nodes/programmability/converter.md',
                    },
                    {
                        title: 'Regex Filter',
                        href: '/nodes/programmability/regex-filter',
                        markdownPath: 'nodes/programmability/regex-filter.md',
                    },
                    {
                        title: 'Set Operations',
                        href: '/nodes/programmability/set-operations',
                        markdownPath: 'nodes/programmability/set-operations.md',
                    },
                    {
                        title: 'Split',
                        href: '/nodes/programmability/split',
                        markdownPath: 'nodes/programmability/split.md',
                    },
                    {
                        title: 'Switch',
                        href: '/nodes/programmability/switch',
                        markdownPath: 'nodes/programmability/switch.md',
                    },
                    {
                        title: 'Unique',
                        href: '/nodes/programmability/unique',
                        markdownPath: 'nodes/programmability/unique.md',
                    }
                ]
            },
            {
                title: 'Other Tools',
                href: 'nodes/other-tools',
                markdownPath: 'nodes/other-tools.md',
                children: [
                    {
                        title: 'History',
                        href: '/nodes/other-tools/history',
                        markdownPath: 'nodes/other-tools/history.md',
                    },
                    {
                        title: 'History Compare',
                        href: '/nodes/other-tools/history-compare',
                        markdownPath: 'nodes/other-tools/history-compare.md',
                    },
                    {
                        title: 'Messenger',
                        href: '/nodes/other-tools/messenger',
                        markdownPath: 'nodes/other-tools/messenger.md',
                        children: [
                            {
                                title: 'Supported messaging systems',
                                href: '/nodes/other-tools/supported-messaging-systems',
                                markdownPath: 'nodes/other-tools/supported-messaging-systems.md',
                            },
                        ]
                    },
                    {
                        title: 'Ticket creator',
                        href: '/nodes/other-tools/ticket-creator',
                        markdownPath: 'nodes/other-tools/ticket-creator.md',
                        children: [
                            {
                                title: 'Supported ticketing systems',
                                href: '/nodes/other-tools/supported-ticketing-systems',
                                markdownPath: 'nodes/other-tools/supported-ticketing-systems.md',
                            },
                        ]
                    },
                ]
            },
        ]
    },
];


export const flatten = (treeData) => {
    var data = [];
    treeData.forEach(elt => {
        data.push(elt);
        if (elt.children) {
            data.push(...flatten(elt.children));
        }
    })
    return data;
}

const insertKeys = (nodes, baseKey = undefined) => {
    return nodes.map((node, idx) => {
        const key = baseKey ? `${baseKey}-${idx}` : `${idx}`
        if (node.children) {
            return {
                ...node,
                key: key,
                children: insertKeys(node.children, key),
            };
        }
        return {
            ...node,
            key: key,
        };
    });
};

const keyedTreeData = insertKeys(treeData);
const routes = flatten(keyedTreeData);

export function getTreeData() {
    return keyedTreeData;
}

export function getRoutes() {
    return routes;
}

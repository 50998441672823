import React from 'react';
import { Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';

const Breadcrumbs = (props) => {
    const breadcrumbItems = [];

    // Add a Home breadcrumb as the first item
    breadcrumbItems.push(
        <Breadcrumb.Item key="home">
            <Link to="/"><span className='home' /> Home</Link>
        </Breadcrumb.Item>
    );

    var partialKey = ""
    props.document.key
        .split('-')
        .forEach(segment => {
            partialKey = partialKey ? `${partialKey}-${segment}` : segment; // Construct the current key
            const route = props.routes.filter(r => r.key === partialKey)[0];

            if (props.document && props.document.title !== "Overview") { // Skip Overview as it is the home icon
                breadcrumbItems.push(
                    <Breadcrumb.Item key={route.key}>
                        {
                            route.href
                                ? <Link to={route.href}>{route.title}</Link>
                                : <>{route.title}</>
                        }
                    </Breadcrumb.Item>
                );
            }
        });

    return (
        <Breadcrumb style={{ margin: '16px 0' }}>
            {breadcrumbItems}
        </Breadcrumb>
    );
};

export default Breadcrumbs;

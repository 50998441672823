import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

export function ScrollToTop() {
    const { pathname, hash } = useLocation();

    useEffect(() => {
        // This one-time interval is needed for react to successfully load the document, otherwise
        // the obj is sometimes not found as the document contains old state.
        let interval = setTimeout(() => {
            var obj = document.getElementById(hash.slice(1));
            var top = obj ? obj.top : 0;

            if (obj) {
                obj.scrollIntoView({ behavior: 'smooth', block: 'start' });
            } else {
                document.documentElement.scrollTo({
                    top: top,
                    left: 0,
                    behavior: "smooth",
                });
            }
            return () => clearInterval(interval)
        }, 100);
    }, [pathname, hash]);

    return null;
}
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/cjs/styles/prism";
import { Button } from "antd";

class PreBlock extends PureComponent {
    static propTypes = {
        value: PropTypes.string.isRequired,
        language: PropTypes.string
    };

    static defaultProps = {
        language: null
    };

    render() {
        const { className, children } = this.props.children[0].props;
        return (
            <div>
                <SyntaxHighlighter
                    style={vscDarkPlus}
                    language={(className ?? "").substring(9)} // Skips hardcoded "language-" part of class names like "langauge-markdown"
                    showLineNumbers={true}
                    >
                    {children[0]}
                </SyntaxHighlighter>
                <Button className="copy-code" icon={<div className="copy-code-img"></div>} onClick={() =>  navigator.clipboard.writeText(children[0])}>Copy</Button>
            </div>
        );
    }
}

export default PreBlock;